<div *ngIf="pagination.count > pagination.limit" class="flex items-center justify-center gap-x-2 py-2">
  <button
    title="Previous page"
    mat-icon-button
    type="button"
    class="rounded-full border border-gray-300 bg-white p-2 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50"
    [disabled]="!pagination.hasPreviousPage() || !pagination.loaded"
    matTooltip="{{ !pagination.hasPreviousPage() || !pagination.loaded ? '' : ('common.BACK' | translate) }}"
    (click)="pagination.queryPreviousPage()">
    <mat-icon> chevron_left </mat-icon>
  </button>

  <span class="pagination" *ngIf="pagination.count > pagination.limit">
    Page {{ pagination.page }} {{ 'page.OF' | translate }}
    {{ ceil(pagination.count / pagination.limit) }}
  </span>

  <span class="pagination" *ngIf="pagination.count <= pagination.limit">
    Page {{ pagination.page }} {{ 'page.OF' | translate }} 1
  </span>

  <button
    title="Next page"
    mat-icon-button
    type="button"
    class="rounded-full border border-gray-300 bg-white p-2 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none disabled:cursor-not-allowed disabled:bg-white disabled:opacity-50"
    [disabled]="!pagination.hasNextPage() || !pagination.loaded"
    matTooltip="{{ !pagination.hasNextPage() || !pagination.loaded ? '' : ('common.NEXT' | translate) }}"
    (click)="pagination.queryNextPage()">
    <mat-icon> chevron_right </mat-icon>
  </button>
</div>
