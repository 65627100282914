export class List {
  id: number;
  author: {
    name: string;
    photo_url: string;
  };
  created: Date;
  updated: Date;
  description: string;
  external_data: any;
  image_url: string;
  is_contributive: boolean;
  itinerary: boolean;
  name: string;
  status: number;
  type: number;
  user: any;
  category: any;
  tags: string[];
  pinpoints_order: number[] = [];

  selected?: boolean;

  constructor(data: any) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        switch (key) {
          case 'created':
          case 'updated':
            this[key] = new Date(data[key]);
            break;
          default:
            this[key] = data[key];
            break;
        }
      }
    }
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      status: this.status,
      itinerary: this.itinerary,
      pinpoints_order: this.pinpoints_order,
      type: this.type,
      category: this.category,
      user: this.user,
      author: this.author,
      created: this.created,
      updated: this.updated,
      image_url: this.image_url,
      tags: this.tags,
      is_contributive: this.is_contributive,
      external_data: this.external_data
    };
  }
}
