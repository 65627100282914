import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { UserService } from '~services/user/user.service';
import { User } from '~models/user/user.model';
import { AbstractDialog } from '../../../../abstracts/abstract-dialog';

@Component({
  selector: 'dialog-list-create-options',
  templateUrl: './create-options.component.html'
})
export class DialogListCreateOptionsComponent extends AbstractDialog<void, void> {
  user: User;

  constructor(
    public dialogRef: MatDialogRef<DialogListCreateOptionsComponent, void>,
    private userService: UserService
  ) {
    super(undefined, dialogRef);
    this.user = this.userService.getUser()!;
  }
}
