<div mat-dialog-content class="w-[500px]">
  <mat-tab-group>
    @if (data.canCreate) {
      <mat-tab label="{{ 'pinpoint.modal.modal-get-select-lists.CREATE-LIST' | translate }}">
        <div class="m-3">
          <label for="list" class="mb-2 block text-sm font-bold text-gray-900">
            {{ 'pinpoint.modal.modal-get-select-lists.LIST-NAME' | translate }}
          </label>

          <input
            id="list"
            name="list"
            type="text"
            [(ngModel)]="listName"
            required
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500" />
        </div>

        <mat-divider></mat-divider>

        <div mat-dialog-actions align="end">
          <button mat-stroked-button class="mr-1 w-1/4" [mat-dialog-close]>
            {{ 'common.CANCEL' | translate }}
          </button>

          <button type="submit" mat-flat-button color="primary" class="ml-1 w-1/4" (click)="createListAndConfirm()">
            {{ 'common.CREATE' | translate }}
          </button>
        </div>
      </mat-tab>
    }

    <mat-tab
      *ngIf="lists.length || searchQuery || isSearching"
      label="{{ 'pinpoint.modal.modal-get-select-lists.SELECT-EXISTING-LIST' | translate }}">
      <div class="relative m-3 flex items-center gap-2">
        <app-text-input
          class="w-full"
          [value]="searchQuery"
          (valueChange)="searchLists($event)"
          [placeholder]="'common.SEARCH' | translate">
        </app-text-input>

        <div class="absolute right-2 flex h-full items-center justify-center" *ngIf="isSearching">
          <mat-spinner diameter="18"></mat-spinner>
        </div>
      </div>

      @if (data.multiple) {
        <mat-checkbox
          class="mt-3"
          [(ngModel)]="selection.checkboxAll"
          (change)="selection.checkboxAll ? selection.selectAllFromSearch() : selection.selectNone()">
          <span class="font-bold"> {{ 'pinpoint.modal.modal-get-select-lists.SELECT-ALL' | translate }}</span>
        </mat-checkbox>

        <div class="ml-5 flex flex-col gap-2">
          <mat-checkbox *ngFor="let list of lists" [ngModel]="list.selected" (change)="selection.toggle(list)">
            {{ list.name }}

            <div class="flex flex-wrap gap-1">
              <app-tag *ngFor="let tag of list.tags" [text]="tag"></app-tag>
            </div>
          </mat-checkbox>
        </div>
      } @else {
        <div class="ml-5 flex flex-col gap-2">
          <mat-radio-group [(ngModel)]="selectedList" class="flex flex-col gap-1">
            <mat-radio-button *ngFor="let list of lists" [value]="list">
              {{ list.name }}

              <div class="flex flex-wrap gap-1">
                <app-tag *ngFor="let tag of list.tags" [text]="tag"></app-tag>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      }

      <app-paginator class="mb-3" [pagination]="pagination"></app-paginator>

      <mat-divider class="mt-3"></mat-divider>

      <div mat-dialog-actions align="end">
        <button mat-stroked-button class="mr-1 w-1/4" [mat-dialog-close]>
          {{ 'common.CANCEL' | translate }}
        </button>

        <button
          mat-flat-button
          color="primary"
          class="ml-1 w-1/4"
          [mat-dialog-close]="data.multiple ? selection.selectedItems : [selectedList]"
          [disabled]="!data.multiple && !selectedList">
          {{ 'common.ADD' | translate }}
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
