import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ListService } from '~services/list/list.service';
import { Pagination } from '~core/pagination/pagination';
import { Selection } from '~core/selection/selection';
import { List } from '~models/list/list.model';
import { AbstractDialog } from '../../../../abstracts/abstract-dialog';
import { UserService } from '~services/user/user.service';

interface DialogListChoiceOptions {
  canCreate?: boolean;
  multiple?: boolean;
}

@Component({
  selector: 'dialog-list-choice',
  templateUrl: './choice.component.html'
})
export class DialogListChoiceComponent extends AbstractDialog<DialogListChoiceOptions, List[]> {
  listName = '';
  lists: List[] = [];
  searchQuery = '';
  private searchSubject = new Subject<string>();

  pagination: Pagination<List>;
  selection: Selection<List>;

  listService: ListService;
  isSearching = false;

  selectedList: List | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<DialogListChoiceComponent, List[]>,
    public injector: Injector,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: DialogListChoiceOptions
  ) {
    super(data, dialogRef);

    this.listService = injector.get(ListService);
    this.pagination = new Pagination<List>(
      this.router,
      this.route,
      this.listService,
      this.handlePaginateChange.bind(this)
    );
    this.pagination.limit = 10;
    this.selection = new Selection<List>(this.pagination, {
      useMaxLimit: true
    });

    this.setupSearch();
    this.pagination.queryFirstPage();
  }

  private setupSearch() {
    combineLatest([
      this.searchSubject.pipe(debounceTime(300), distinctUntilChanged()),
      this.userService.user$
    ]).subscribe(([query, user]) => {
      this.isSearching = true;
      this.pagination.filter = {
        query,
        user: user?.id
      };
      this.pagination.queryFirstPage();
    });
  }

  handlePaginateChange(lists: List[]) {
    this.lists = lists;
    if (this.data.multiple) {
      this.selection.items = this.lists;
    }
    this.isSearching = false;
  }

  createListAndConfirm() {
    const list = new List({
      name: this.listName,
      status: 3
    });

    this.listService.save(list).subscribe((response) => {
      this.dialogRef.close([response]);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  searchLists(query: string) {
    this.searchQuery = query;
    this.searchSubject.next(this.searchQuery);
  }

  selectList(list: List) {
    this.selectedList = list;
  }
}
